<template>
  <div v-if="balance.available === undefined" v-loading="loading"></div>
  <div v-else class="layout">
    <div class="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
      <h2 class="text-left text-lg font-medium leading-6 text-gray-900">
        Overview
      </h2>
      <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <!-- Balance -->
        <div class="overflow-hidden rounded-lg bg-white shadow">
          <div class="p-5 text-left">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <RefreshIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-5 w-0 flex-1">
                <dl>
                  <dt
                    class="flex justify-between truncate text-sm font-medium text-gray-500"
                  >
                    <span>Pending Payments</span>
                    <ShowInfo text="Stripe not yet processed " />
                  </dt>
                  <dd>
                    <div class="text-lg font-medium text-gray-900">
                      $ {{ balance.available[0].amount }}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-5 py-3">
            <div class="text-left text-sm">
              <a href="#" class="text-cyan-700 hover:text-cyan-900 font-medium">
                View all
              </a>
            </div>
          </div>
        </div>

        <!-- Transactions -->
        <div class="overflow-hidden rounded-lg bg-white shadow">
          <div class="p-5 text-left">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  class="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-5 w-0 flex-1">
                <dl>
                  <dt
                    class="flex justify-between truncate text-sm font-medium text-gray-500"
                  >
                    <span>Processed Payments</span>
                    <ShowInfo text="Need Description " />
                  </dt>
                  <dd>
                    <div class="text-lg font-medium text-gray-900">
                      $ {{ balance.pending[0].amount / 100 }}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-5 py-3">
            <div class="text-left text-sm">
              <a
                href="#"
                @click.prevent="showProcessedPayments"
                class="text-cyan-700 hover:text-cyan-900 font-medium"
              >
                View all
              </a>
            </div>
          </div>
        </div>

        <div class="overflow-hidden rounded-lg bg-white shadow">
          <div class="p-5 text-left">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <RefreshIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-5 w-0 flex-1">
                <dl>
                  <dt
                    class="flex justify-between truncate text-sm font-medium text-gray-500"
                  >
                    <span>Sent Invoices</span>
                    <ShowInfo text="Need Description " />
                  </dt>
                  <dd>
                    <div class="text-lg font-medium text-gray-900">$ 0</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-5 py-3">
            <div class="text-left text-sm">
              <a href="#" class="text-cyan-700 hover:text-cyan-900 font-medium">
                View all
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 flex flex-col" v-if="state.toggleTransactions">
        <div class="mt-4 mb-4 flex justify-between">
          <h2 class="text-left text-lg font-medium leading-6 text-gray-900">
            Processed Payments
          </h2>
          <button
            type="button"
            @click="state.toggleTransactions = false"
            class="focus:outline-none inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm"
          >
            Hide
          </button>
        </div>
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Net
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Fee
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Created
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white text-left">
                  <tr v-for="transaction in transactions" :key="transaction.Id">
                    <td
                      class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                    >
                      {{ transaction.Amount }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {{ transaction.Net }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {{ transaction.Fee }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {{ transaction.Created }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted } from "vue";

import { RefreshIcon, CheckCircleIcon } from "@heroicons/vue/outline";
import ShowInfo from "@/common/components/showInfo";
import useConnectionsRepository from "@/composable/useConnectionsRepository";

export default defineComponent({
  components: {
    ShowInfo,
    RefreshIcon,
    CheckCircleIcon,
  },
  setup() {
    const state = reactive({
      toggleTransactions: false,
    });

    const {
      loading,
      balance,
      transactions,
      getStripeBalance,
      getStripeTransactions,
    } = useConnectionsRepository();

    const showProcessedPayments = () => {
      getStripeTransactions();
      state.toggleTransactions = true;
    };

    onMounted(() => {
      getStripeBalance();
    });

    return {
      state,
      balance,
      loading,
      transactions,
      showProcessedPayments,
    };
  },
});
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  overflow-y: scroll;
  width: 100%;
  position: relative;
}
</style>
