<template>
  <InformationCircleIcon
    ref="info"
    class="h-5 w-5 text-purple-400"
    aria-hidden="true"
  />
</template>

<script>
import { ref } from "vue";
import { useTippy } from "vue-tippy";
import { InformationCircleIcon } from "@heroicons/vue/outline";

import "tippy.js/animations/scale.css";
import "tippy.js/themes/light.css";

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  components: {
    InformationCircleIcon,
  },
  setup(props) {
    const info = ref(null);
    useTippy(info, {
      content: props.text,
      arrow: false,
      theme: "light",
      animation: "scale",
    });

    return {
      info,
    };
  },
};
</script>
