import { ref, readonly } from "vue";
import { ElNotification } from "element-plus";
import API from "@/api";

export default function useConnectionsRepository() {
  const balance = ref({});
  const transactions = ref([]);
  const loading = ref(false);

  const getStripeBalance = async () => {
    loading.value = true;
    let response = await API.Organization.Connections.stripeBalance();

    if (response.data.Success) {
      balance.value = response.data.Item;
      loading.value = false;
    } else {
      ElNotification({
        title: "Oops",
        message: "Problem while fetching Stripe balance",
        type: "warning",
      });
    }
  };

  const getStripeTransactions = async () => {
    loading.value = true;
    let response = await API.Organization.Connections.stripeTransactions();

    if (response.data.Success) {
      transactions.value = response.data.List;
      loading.value = false;
    } else {
      ElNotification({
        title: "Oops",
        message: "Problem while fetching Stripe transactions",
        type: "warning",
      });
    }
  };

  return {
    loading: readonly(loading),
    balance: readonly(balance),
    transactions: readonly(transactions),
    getStripeBalance,
    getStripeTransactions,
  };
}
